import React from "react"
import {graphql } from "gatsby"

import Home from "../layouts/home"

{/*
  We aren't really using this, but it needs to be here for when the site is initially built
*/}

export default function postViewer({ data }) {

  const {title, content} = data.allWpPost.nodes[0]

  return (
    <Home>
      <div className='subtitle'>{title}</div>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Home>
  )
}

export const query = graphql`
  query getPosts($id: String!) {
    allWpPost(filter: {id: { eq: $id }}) {
      nodes {
        id
        title
        slug
        content
        date
      }
    }
  }
`
